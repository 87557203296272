<template>
    <div class="home">
        <div style="width: 100%;height: .48rem;background-color: #fff7e9;"></div>
        <div class="homeMain">
            <div class="homebannerBox">
                <div class="swiper mySwiper5">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in bannerDate" @click="bannerJop1(item.sj_link)" :key="index">
                            <img :src="'https://public.rossoarts.com/static/uploads/' + item.sj_banner" alt="">
                        </div>
                        <div class="swiper-slide" @click="artNewJop(artsFirstId)">
                            <div class="newBanner">
                                <div class="newBanner_top">
                                    <p>RoSSo</p>
                                    <div style="width: 2.47rem; display: flex;justify-content: space-between;">
                                        <p>{{ artsFirst.create_time }}</p>
                                        <img src="../img/banNew/2.png" alt="">
                                    </div>
                                </div>
                                <div class="newBanner_title">
                                    <div class="newBanner_title_left">
                                        <p>{{ artsFirst.title }}</p>
                                    </div>
                                    <div class="newBanner_title_right">
                                        <img :src="'https://public.rossoarts.com/static/uploads/article/' + artsFirst.list_img"
                                            alt="">
                                    </div>
                                </div>
                                <div class="newBanner_bottom">
                                    <img src="../img/banNew/3.png" alt="">
                                </div>
                            </div>
                        </div>

                    </div>
                   
                    <div class="swiper-pagination"></div>

                </div>

            </div>
            <div class="title_slogn">要OFFER，更要能力，伴你至就业</div>
        </div>

        <ul class="bannerAfter">
            <li>
                <p class="bannerAfterP1">22,000+</p>
                <p class="bannerAfterP2">OFFER</p>
                <p class="bannerAfterP2">收割</p>
            </li>
            <li>
                <p class="bannerAfterP1">52.1%</p>
                <p class="bannerAfterP2">奖学金</p>
                <p class="bannerAfterP2">比例助力</p>
            </li>

            <li>
                <p class="bannerAfterP1">10+1所</p>
                <p class="bannerAfterP2">直营+海外校区 </p>
                <p class="bannerAfterP2">坐落核心地段</p>
            </li>
            <li>
                <p class="bannerAfterP1">1000+</p>
                <p class="bannerAfterP2">海内外</p>
                <p class="bannerAfterP2">优质导师</p>
            </li>
            <li>
                <p class="bannerAfterP1">70+</p>
                <p class="bannerAfterP2">背提项目</p>
                <p class="bannerAfterP2">提高竞争力</p>
            </li>
        </ul>


        <div class="section1">
            <div class="s1_title_box">
                <img src="../img/Star 1.png" alt="">
                <div>
                    <p class="cn">RoSSo录取榜单</p>
                    <p class="en">OFFERLIST</p>
                </div>
                <img src="../img/Star 1.png" alt="">
            </div>

            <div class="s1_cont">
                <div class="swiper mySwiper2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in schoolData" :key="index">
                            <div class="s1_item" v-for="(item2, index2) in item"
                                @click="jopschoolDetail(item2.id, item2.cname)" :key="index2">
                                <img :src="'https://public.rossoarts.com/static/uploads/' + item2.logo" alt="">
                                <p>{{ item2.cname }}</p>
                                <p>录取<span>{{ item2.admission_num }}</span>人</p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
                <!-- <img src="../img/Group 236.png" alt="" class="s1_btn"> -->
                <div class="s33_btn" @click="schoolJop">
                    <p>查看更多录取案例</p>
                    <img src="../img/Group 657.png" alt="">
                </div>

            </div>

        </div>


        <div class="section2">
            <div class="s2_item">
                <img src="../img/Group 137.png" alt="">
                <p class="s2_itemP1">总申请人数</p>
                <p class="s2_itemP2">16000+人</p>
            </div>

            <div class="s2_item">
                <img src="../img/Group 138.png" alt="">
                <p class="s2_itemP1">梦校录取人数</p>
                <p class="s2_itemP2">15700+人</p>
            </div>
            <div class="s2_item">
                <img src="../img/Group 139.png" alt="">
                <p class="s2_itemP1">本科OFFER</p>
                <p class="s2_itemP2">10300+份</p>
            </div>
            <div class="s2_item">
                <img src="../img/Group 140.png" alt="">
                <p class="s2_itemP1">硕士OFFER</p>
                <p class="s2_itemP2">11700+份</p>
            </div>

        </div>


        <div class="section31">
            <div class="section_box">
                <div class="section_title_cn">核心优势</div>
                <div class="section_title_en">RoSSo EXCLUSIVE</div>

            </div>

            <div class="s31_cont">
                <div class="s31_item">
                    <div class="s31_item_cover">
                        <img src="../img/cover1.jpg" alt="">
                        <div></div>

                    </div>
                    <div class="s31_item_box">
                        <div class="s31_item_top">
                            <img src="../img/Group 296.png" alt="">
                            <div class="cn">
                                <div>教育体系</div>
                                <div>全面升级</div>
                            </div>

                        </div>
                        <div class="s31_item_bot">
                            <p class="cn">六大科系全面升级</p>
                            <p class="cn">融入英美院校学习概念</p>
                            <p class="cn">培养跨学科综合实践能力</p>
                        </div>
                    </div>

                </div>
                <div class="s31_item">
                    <div class="s31_item_cover">
                        <img src="../img/cover2.jpg" alt="">
                        <div></div>

                    </div>
                    <div class="s31_item_box">
                        <div class="s31_item_top">
                            <img src="../img/Group 298.png" alt="">

                            <div class="cn">
                                <div>多V1</div>
                                <div>教育模式</div>
                            </div>

                        </div>
                        <div class="s31_item_bot">
                            <p class="cn">配置专属留学规划师</p>
                            <p class="cn">教学部安排多位导师授课</p>
                            <p class="cn">专属教务跟踪教学情况</p>
                        </div>
                    </div>

                </div>
                <div class="s31_item">
                    <div class="s31_item_cover">
                        <img src="../img/cover3.jpg" alt="">
                        <div></div>

                    </div>
                    <div class="s31_item_box">
                        <div class="s31_item_top">
                            <img src="../img/Group 297.png" alt="">

                            <div class="cn">
                                <div>五维教育</div>
                                <div>审核体系</div>
                            </div>
                        </div>
                        <div class="s31_item_bot">
                            <p class="cn">内部团队与外部团队协作</p>
                            <p class="cn">打造专业审核部门</p>
                            <p class="cn">严格把控教学质量</p>
                        </div>
                    </div>

                </div>
                <div class="s31_item">
                    <div class="s31_item_cover">
                        <img src="../img/cover4.jpg" alt="">
                        <div></div>

                    </div>
                    <div class="s31_item_box">
                        <div class="s31_item_top">
                            <img src="../img/Group 299.png" alt="">

                            <div class="cn">
                                <div>海内外院校</div>
                                <div>导师团队</div>
                            </div>
                        </div>
                        <div class="s31_item_bot">
                            <p class="cn">只聘请海外院校研究生</p>
                            <p class="cn">解析院校申请痛点难点</p>
                            <p class="cn">10000+学生收获院校offer</p>
                        </div>
                    </div>

                </div>


            </div>

            <a href="javascript:void(0)" onclick="ydtopenchat()">
                <div class="s33_btn">
                    <img src="../img/Group 658.png" alt="">
                    <p>即刻咨询</p>
                </div>
            </a>
        </div>

        <div class="section32">
            <div class="section_box">
                <div class="section_title_cn">艺术留学课堂</div>
                <div class="section_title_en">RoSSo ART COURSES</div>

            </div>

            <div class="s32_cont">
                <div class="swiper mySwiper6">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="s32_item">
                                <p>1. VIP院校计划</p>
                                <ul>
                                    <li>无缝衔接院校课程体系</li>
                                    <li>协议录取指定欧美院校</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/vip.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>

                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item">
                                <p>2. 专业作品集辅导</p>
                                <ul>
                                    <li>多位导师打造个性化作品集服务</li>
                                    <li>在教学中培养学生自主能力</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/sample.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item">
                                <p>3. 海外游学项目</p>
                                <ul>
                                    <li>提供国际名校寒暑假游学项目</li>
                                    <li>抢先获得院校教授推荐信</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/promoting')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="s32_item">
                                <p>4. BTEC艺术课程</p>
                                <ul>
                                    <li>培生爱德思官方授权</li>
                                    <li>低成本高性价比申请通道</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/btec.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item">
                                <p>5. A-LEVEL课程</p>
                                <ul>
                                    <li>培生爱德思官方授权</li>
                                    <li>实现学术与申请高效同步</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/alevel.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item">
                                <p>6. GA&GD创意课程</p>
                                <ul>
                                    <li>针对12-16 青少年系统地学习艺术与设计课程</li>
                                    <li>1V1专属升学指导规划</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/gagd.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="s32_item">
                                <p>7. AP课程</p>
                                <ul>
                                    <li>美本名校直录最佳助力</li>
                                    <li>灵活授课，成绩可抵扣本科学分</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/ap.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item">
                                <p>8. RoSSo College</p>
                                <ul>
                                    <li>与国际学校强强联手</li>
                                    <li>全方面发展高素质人才</li>
                                </ul>
                                <div class="s32_btn_box">
                                    <div class="s32_kefu_btn" @click="jopCourseDetail('/PortfolioInfo/RossoCollege.html')">
                                        <p>课程详情</p>
                                        <img src="../img/Frame2.png" alt="">
                                    </div>
                                    <a href="javascript:void(0)" onclick="ydtopenchat()" class="s32_kefu_btn"
                                        style="background-color: #fb6333;">
                                        <p style="color: #fff;">预约试听</p>
                                        <img src="../img/Frame.png" alt="">
                                    </a>


                                </div>
                            </div>
                            <div class="s32_item" style="background: none;">

                            </div>
                        </div>



                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>

        </div>

        <div class="section33">
            <div class="section_box">
                <div class="section_title_cn">艺术导师</div>
                <div class="section_title_en">ART TUTORS</div>

            </div>

            <div class="s33_togbtn_box">
                <div class="s33_togbtn" @click="togCourses(0)" style="color: #fff;background-color: #5F9DF7;">海外大师</div>
                <div class="s33_togbtn" @click="togCourses(1)" style="background-color: #fff;">学科带头人</div>
            </div>

            <div class="s33_cont1">
                <div class="swiper mySwiper331">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="s33_item" v-for="(item, index) in tutors1Data2" :key="index" v-if="index < 6" 
                                @click="jopTutorDetail(item.id)">
                                <div class="s33imgBox">
                                    <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                        alt="">
                                    <img class="s33coverImg" v-if="index == 0 || index == 5"
                                        src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                    <img class="s33coverImg" v-if="index == 1 || index == 3"
                                        src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                    <img class="s33coverImg" v-if="index == 2 || index == 4"
                                        src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                </div>
                                <p class="s33p1">Prof.{{ item.name }}</p>
                                <p class="s33p2">{{ item.major }}</p>
                                <p class="s33p3">{{ item.school | schoolAd }}</p>
                            </div>

                        </div>
                        <div class="swiper-slide">
                            <div class="s33_item" v-for="(item, index) in tutors1Data2" v-if="index > 5"
                                @click="jopTutorDetail(item.id)">
                                <div class="s33imgBox">
                                    <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                        alt="">
                                    <img class="s33coverImg" v-if="index == 6 || index == 11"
                                        src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                    <img class="s33coverImg" v-if="index == 7 || index == 9"
                                        src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                    <img class="s33coverImg" v-if="index == 8 || index == 10"
                                        src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                </div>
                                <p class="s33p1">Prof.{{ item.name }}</p>
                                <p class="s33p2">{{ item.major }}</p>
                                <p class="s33p3">{{ item.school | schoolAd }}</p>
                            </div>


                            <div class="s33_item">

                            </div>
                        </div>

                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>


            <div class="s33_cont1" style="display: none;">
                <div class="swiper mySwiper331">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="s33_item" v-for="(item, index) in tutors1Data" :key="index" v-if="index < 6"
                                @click="jopTutorDetail(item.id)">
                                <div class="s33imgBox">
                                    <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                        alt="">
                                    <img class="s33coverImg" v-if="index == 0 || index == 5"
                                        src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                    <img class="s33coverImg" v-if="index == 1 || index == 3"
                                        src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                    <img class="s33coverImg" v-if="index == 2 || index == 4"
                                        src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                </div>
                                <p class="s33p1">{{ item.name }}</p>
                                <p class="s33p2">{{ item.major }}</p>
                                <p class="s33p3">{{ item.school | schoolAd }}</p>
                            </div>

                        </div>
                        <div class="swiper-slide">
                            <div class="s33_item" v-for="(item, index) in tutors1Data" :key="index" v-if="index > 5"
                                @click="jopTutorDetail(item.id)">
                                <div class="s33imgBox">
                                    <img :src="' https://public.rossoarts.com/static/uploads/teacher/' + item.avatar"
                                        alt="">
                                    <img class="s33coverImg" v-if="index == 6 || index == 11"
                                        src="../img/homepage-折叠部分切图/Group 213.png" alt="">
                                    <img class="s33coverImg" v-if="index == 7 || index == 9"
                                        src="../img/homepage-折叠部分切图/Group 214.png" alt="">
                                    <img class="s33coverImg" v-if="index == 8 || index == 10"
                                        src="../img/homepage-折叠部分切图/Group 215.png" alt="">

                                </div>
                                <p class="s33p1">{{ item.name }}</p>
                                <p class="s33p2">{{ item.major }}</p>
                                <p class="s33p3">{{ item.school | schoolAd }}</p>
                            </div>
                            <div class="s33_item">

                            </div>
                        </div>

                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>



            <div class="s33_btn" @click="jopTutor">
                <img src="../img/Group 659.png" alt="">
                <p>了解更多</p>
            </div>


        </div>


        <div class="section34">
            <div class="section_box">
                <div class="section_title_cn">优秀案例</div>
                <div class="section_title_en">SELECTED CASES</div>

            </div>
            <div class="s34_cont">
                <div class="swiper mySwiper333">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in selectedData">
                            <div class="s34_item" v-for="(item2, index2) in item" @click="caseDetail(item2.id)">
                                <p class="s34_p1">RoSSo</p>
                                <img :src="'https://public.rossoarts.com/static/uploads/case/' + item2.portfolioShow"
                                    alt="">
                                <p class="s34_p2">{{ item2.name }}</p>
                                <p class="s34_p3">{{ item2.cname }}</p>
                                <p class="s34_p3">{{ item2.school | schoolSplit }}</p>
                            </div>

                        </div>


                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>

        </div>


        <div class="section4">
            <div class="section_box">
                <div class="section_title_cn">洛素制造</div>
                <div class="section_title_en">BY RoSSo</div>

            </div>

            <div class="s4_cont">
                <div class="s4_top">

                    <p class="cn">“By
                        RoSSo”是由RoSSo国际艺术留学于2020年创立的独立艺术聚合空间，集结优秀青年艺术家、独立艺术品牌并为其提供商业化桥梁，为大众呈现冰山之下的当代语境美学以及非常规艺术设计作品。“By
                        RoSSo”的品牌愿景不止于打造青年艺术家/品牌的交流及孵化平台，也希望为每一份热爱提供恒久燃烧的火把。
                    </p>

                </div>

                <div class="s8_right">
                    <div class="s8clientBox">

                        <div class="s8banner" id="s8banner">
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                            <div class="s8r_top">
                                <div class="s8img1"></div>
                                <div class="s8img2"></div>

                            </div>
                        </div>

                    </div>

                    <div class="s8_bot">
                        <div class="s8pre" @click="s8pre()"></div>
                        <div class="s8name">
                            <p id="collectionName">Caoyang</p>
                            <p id="artsName">「 Plastic love 」</p>
                        </div>
                        <div class="s8nex" @click="s8next()"></div>
                    </div>
                </div>


            </div>
        </div>

        <div class="section5">
            <div class="section_box">
                <div class="section_title_cn">最新资讯</div>
                <div class="section_title_en">NEWS & EVENTS</div>

            </div>
            <div class="s5_cont">

                <div class="swiper mySwiper7">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in artsNewdata" :key="item.id"
                            @click="jopNewsDetail(item.id)">
                            <div class="s5_img_box">
                                <img :src="'https://public.rossoarts.com/static/uploads/article/' + item.list_img" alt=""
                                    class="really_img">
                                <img src="../img/Exclude.png" alt="" class="cover_img">
                            </div>
                            <div>
                                <p class="p1 en">{{ item.create_time }}</p>
                                <p class="p2 en">iOFFER</p>
                                <p class="p3 cn">{{ item.title }}</p>
                            </div>
                        </div>
                        <!-- <div class="swiper-slide">Slide 2</div>
            <div class="swiper-slide">Slide 3</div>
            <div class="swiper-slide">Slide 4</div>
            <div class="swiper-slide">Slide 5</div>
            <div class="swiper-slide">Slide 6</div>
            <div class="swiper-slide">Slide 7</div>
            <div class="swiper-slide">Slide 8</div>
            <div class="swiper-slide">Slide 8</div> -->


                    </div>
                    <div class="swiper-pagination"></div>
                </div>

            </div>
        </div>

        <img src="../img/s45cont.jpg" alt="" style="width: 100%;" @click="coverInp">

        <div class="section6" style="margin-top: 1.2rem;">
            <div class="section_box">
                <div class="section_title_cn">全国教学环境</div>
                <div class="section_title_en">TEACHING ENVIRONMENTS</div>

            </div>

            <div class="s6_cont">
                <div class="s6_area">
                    <div class="envItem" @click="envChange(0)">上海</div>
                    <div class="envItem" @click="envChange(1)">北京</div>
                    <div class="envItem" @click="envChange(2)">南京</div>
                    <div class="envItem" @click="envChange(3)">成都</div>
                    <div class="envItem" @click="envChange(4)">杭州</div>
                    <div class="envItem" @click="envChange(5)">广州</div>
                    <div class="envItem" @click="envChange(6)">深圳</div>
                    <div class="envItem" @click="envChange(7)">武汉</div>
                </div>

                <div class="s5_env">


                    <div class="s5_right">

                        <div class="swiper mySwiper52">

                            <div class="swiper-wrapper">

                                <div class="swiper-slide">
                                    <img :src="envImg1" alt="">
                                </div>
                                <div class="swiper-slide">
                                    <img :src="envImg2" alt="">
                                </div>
                                <div class="swiper-slide">
                                    <img :src="envImg3" alt="">
                                </div>
                                <div class="swiper-slide">
                                    <img :src="envImg4" alt="">
                                </div>
                                <div class="swiper-slide">
                                    <img :src="envImg5" alt="">
                                </div>
                            </div>

                            <!-- <div class="swiper-pagination"></div> -->
                        </div>

                        <!-- <div class="s5_right_client"> </div> -->
                    </div>

                    <div class="s5_left">

                        <!-- <p class="s5_p1" id="s5p1">上海总部</p>
              <p class="s5_p2" id="s5p2">SHANGHAI</p> -->
                        <p class="s5_p3" id="s5p3">上海徐汇区文定路208号A座301-302室</p>
                        <!-- <div class="s5_line"></div> -->

                    </div>
                </div>

            </div>


        </div>


        <div class="section_inp">
            <div class="s_inp_left">
                <!-- <img src="../img/手部插画.png" alt="" class="img_shou animated" id="imgShou"> -->
                <img src="../img/Group 302.png" alt="" class="img_shou" id="imgText">
            </div>
            <div class="s_inp_right">
                <el-form :inline="true" :label-position="labelPosition" label-width="1.2rem" :model="formLabelAlign">
                    <el-form-item label="姓名：">
                        <el-input v-model="formLabelAlign.name" placeholder="输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="预约校区：" class="elitem2">
                        <el-select v-model="formLabelAlign.region" placeholder="预约校区">
                            <el-option label="上海" value="shanghai"></el-option>
                            <el-option label="北京" value="beijing"></el-option>
                            <el-option label="南京" value="nanjing"></el-option>
                            <el-option label="成都" value="chengdu"></el-option>
                            <el-option label="杭州" value="hangzhou"></el-option>
                            <el-option label="广州" value="guangzhou"></el-option>
                            <el-option label="深圳" value="shenzhen"></el-option>
                            <el-option label="武汉" value="wuhan"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="formLabelAlign.tel" placeholder="输入电话号"></el-input>
                    </el-form-item>
                    <el-form-item label="意向国家：" class="elitem2">
                        <el-select v-model="formLabelAlign.region2" placeholder="国家">
                            <el-option label="英国" value="England"></el-option>
                            <el-option label="美国" value="America"></el-option>
                            <el-option label="日本" value="Japan"></el-option>
                            <el-option label="新加坡" value="Singapore"></el-option>
                            <el-option label="韩国" value="Korea"></el-option>
                            <el-option label="欧洲" value="Europe"></el-option>

                            <el-option label="其他" value="Other"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="意向专业：">
                        <el-input v-model="formLabelAlign.major" placeholder="意向专业"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="yuyue" @click="onSubmit()"></div>
                    </el-form-item>
                </el-form>
            </div>

        </div>

        <div class="section7">
            <div class="section_box">
                <div class="section_title_cn">RoSSo荣誉</div>
                <div class="section_title_en">RoSSo HONORS</div>
            </div>
            <div class="s7_cont">
                <div class="s7_left">
                    <ul>
                        <li>
                            <div></div>
                            <p>英国培生爱德思学术及职业考试机构认证中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国培生爱德思学术及职业考试机构认证中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国国家课程A Level考试局官方办学中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国爱德思考试局官方授权GCSE考点</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国BTEC预科课程官方办学中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国国家职业学历与学术考试官方认证中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国伦敦圣三一考试院官方考点</p>
                        </li>
                        <li>
                            <div></div>
                            <p>EPQ研究型学习认证项目</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国爱德思考试局官方授权考点</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国大学招生服务中心UCAS认证考评中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>英国资格认证和考试管理办公室朗思测评合作中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>腾讯留学缴费官方指定缴费基地</p>
                        </li>
                        <li>
                            <div></div>
                            <p>伦敦艺术大学UAL特殊预审中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>日本文化集团大陆事务所指定合作单位</p>
                        </li>
                        <li>
                            <div></div>
                            <p>日本文化外国语学院指定招生中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>伦敦艺术大学UAL授权国际预科课程IFD合作中心</p>
                        </li>
                        <li>
                            <div></div>
                            <p>伯恩茅斯艺术大学官方合作单位</p>
                        </li>

                    </ul>


                </div>
                <div class="s7_right">
                    <div class="swiper mySwiper8">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors1.jpg" alt="" @click="imgCover($event)">
                                <p>2020回响中国腾讯教育年度盛典影响力国际教育品牌</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors5.jpg" alt="" @click="imgCover($event)">
                                <p>早稻田EDU官方授权合作中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors6.jpg" alt="" @click="imgCover($event)">
                                <p>日本文化外国语学院指定招生中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors7.jpg" alt="" @click="imgCover($event)">
                                <p>朗思Language Cert2022年度最佳合作伙伴</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors3.jpg" alt="" @click="imgCover($event)">
                                <p>2022年获得培生爱德思考试局 “摘星揽月”奖 年度优秀教学中心</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors1.jpg" alt="" @click="imgCover($event)">
                                <p>2020回响中国腾讯教育年度盛典影响力国际教育品牌</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors4.jpg" alt="" @click="imgCover($event)">
                                <p>2020年度百度营销最具品牌影响力</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors8.jpg" alt="" @click="imgCover($event)">
                                <p>2020年度最具品牌影响力</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors9.jpg" alt="" @click="imgCover($event)">
                                <p>2020年度影响力国际教育品牌</p>
                            </div>
                            <div class="swiper-slide">
                                <img src="../img/RoSSo荣誉/honors2.jpg" alt="" @click="imgCover($event)">
                                <p>2019年度新浪教育盛典家长信赖教育培训品牌</p>
                            </div>

                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section8">
            <div class="section_box">
                <div class="section_title_cn">合作伙伴</div>
                <div class="section_title_en">COOPERATION ORGANIZATIONS</div>
            </div>
            <div class="s8_cont">
                <div class="s7_banner">

                    <div class="s7_line s7_banner3">
                        <img src="../img/合作伙伴最新版/院校/jq.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jzys.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/dmms.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lfb.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/tsd.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/glsg.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ytdx.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/meb.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nyu.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nydy.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/MI.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/FIDM.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/saic.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lwp.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsd.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/mllk.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/jq.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jzys.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/dmms.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lfb.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/tsd.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/glsg.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/ytdx.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/meb.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nyu.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/nydy.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/MI.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/FIDM.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/saic.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/lwp.png" alt="">
                        <img src="../img/合作伙伴最新版/院校/jsd.jpg" alt="">
                        <img src="../img/合作伙伴最新版/院校/mllk.jpg" alt="">
                    </div>

                    <div class="s7_line">
                        <img src="../img/合作伙伴最新版/品牌/2021 OpenFile Generic Proposal_CHN-compressed.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RBDA_Logo_White.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/TXLOGO.jpg" alt=""> <!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1961.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1962.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1963.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1964.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1965.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1967.jpg" alt=""><!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1970.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1971.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1972.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1973.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1974.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1975.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1976.jpg" alt="">


                        <img src="../img/合作伙伴最新版/品牌/2021 OpenFile Generic Proposal_CHN-compressed.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/RBDA_Logo_White.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/TXLOGO.jpg" alt=""> <!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1961.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1962.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1963.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1964.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1965.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1967.jpg" alt=""><!-- 切 -->
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1970.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1971.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1972.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1973.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1974.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1975.jpg" alt="">
                        <img src="../img/合作伙伴最新版/品牌/WechatIMG1976.jpg" alt="">

                    </div>
                </div>
            </div>
        </div>

        <!-- 最外层div -->


    </div>
</template>

<script>
// wlh
// @ is an alias to /src
import Swiper from 'swiper/bundle'
import 'swiper/css';
import "swiper/css/effect-cards";
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '@/assets/css/home.css'
// import 'swiper/swiper-bundle.css'
import axios from "axios";
export default {
    // metaInfo: {
    //   title: '我是users头', // set a title
    //   meta: [{             // set meta
    //     name: 'keyWords',
    //     content: '我是users关键字'
    //   },
    //   {
    //     name: 'description',
    //     content: '我是users描述'
    //   }],
    //   link: [{ // set link
    //     rel: 'asstes',
    //     href: 'https://assets-cdn.github.com/'
    //   }]
    // },
    data() {
        return {
            s8I: 0,
            collectionName: ["Caoyang", "Jiayu", "Matti", "Qingyang", "Ruiling", "Yaosichen", "Caoyang"],
            artsName: ["「 Plastic love 」", "「 Bang Gril 」", "「 Empathy 」", "「 Hearing final」", "「 Face Blind 」", "「 Sub 」", "「 Plastic love 」"],
            artsNewdata: [],
            envCont: [
                {
                    name: "上海",
                    ename: "SHANGHAI",
                    address: "上海徐汇区文定路208号A座"
                },
                {
                    name: "北京",
                    ename: "BEIJING",
                    address: `一校：朝阳区百子湾路32号院苹果社区北区6号楼88号<br>二校：海淀区丹棱街6号丹棱SOHO 619`
                },
                {
                    name: "南京",
                    ename: "NANJING",
                    address: "南京市秦淮区中山南路1号南京中心20楼"
                },
                {
                    name: "成都",
                    ename: "CHENGDU",
                    address: "成都锦江区成都国际金融中心IFS一号楼"
                },
                {
                    name: "杭州",
                    ename: "HANGZHOU",
                    address: "杭州西湖区学院路77号黄龙万科中心"
                },
                {
                    name: "广州",
                    ename: "GUANGZHOU",
                    address: "广州市天河区中信广场"
                },
                {
                    name: "深圳",
                    ename: "SHENZHEN",
                    address: "深圳福田区金田路东福华路北岗厦皇庭大厦"
                },
                {
                    name: "武汉",
                    ename: "WUHAN",
                    address: "武汉武昌区中北路9号长城汇"
                },
            ],
            labelPosition: 'right',
            formLabelAlign: {
                name: '',
                tel: '',
                region: '',
                region2: '',
                major: '',
            },
            schoolData: [],
            tutors1Data: [],
            tutors1Data2: [],
            selectedData: [],
            bannerDate: [],
            envImg1: require("../img/各校区门头图/上海/官网/1.jpg"),
            envImg2: require("../img/各校区门头图/上海/官网/2.jpg"),
            envImg3: require("../img/各校区门头图/上海/官网/3.jpg"),
            envImg4: require("../img/各校区门头图/上海/官网/4.jpg"),
            envImg5: require("../img/各校区门头图/上海/官网/5.jpg"),
            offerSrc: "",
            artsFirst: [],
            artsFirstId: '',
        }
    },

    created() {
        this.getArtsNew()
        this.getSchool()
        this.getTutors1()
        this.getTutors2()
        this.getSelected()
        this.getPCbanner()

    },
    filters: {
        schoolSplit(val) {
            // const valLength = val.split("/").length
            // return valLength<2?val.split("/")[0]:val.split("/")[0] +"/" + val.split("/")[1]
            return val.split("/")[0]
        },
        schoolAd(val) {
            // const valLength = val.split("/").length
            // return valLength<2?val.split("/")[0]:val.split("/")[0] +"/" + val.split("/")[1]
            return val.split(",")[0]
        },
    },
    methods: {
        coverInp() {
            var coverBlack3 = document.getElementById("coverBlack3")
            coverBlack3.style.display = "block"
        },
        caseDetail(val) {
            this.$router.push(`/case/${val}.html`)
        },
        schoolJop() {

            this.$router.push(`${this.offerSrc}`)
        },
        jopTutorDetail(val) {
            this.$router.push(`/teachers/${val}.html`)
        },
        jopTutor() {
            this.$router.push(`/teacher.html`)
        },
        jopCourseDetail(val) {
            this.$router.push(`${val}`)
        },
        jopschoolDetail(val, val2) {
            // this.$router.push(`/schools/${val}.html`)
            this.$router.push({

                path: `/schools/${val}.html?cname=${val2}`,

            })
        },
        jopNewsDetail(val) {
            this.$router.push(`/news/${val}.html`)
        },
        imgCover(e) {
            // var img = document.getElementsByTagName("img")
            var coverBlack = document.getElementById("coverBlack")
            // console.log(coverBlack);
            coverBlack.style.display = "block"
            coverMainImg.src = e.target.src
            coverMainImg.style.width = "80%"
            // console.log(img);
            // console.log(e.target.src);
        },
        arrGroup(array, sublength) {
            let index = 0;
            let newArray = []
            while (index < array.length) {
                newArray.push(array.slice(index, index += sublength))
            }
            return newArray
        },
        getPCbanner() {
            axios.get("https://public.rossoarts.com/api/v1/getSjBanner").then((data) => {
                // console.log(data.data.data);
                this.bannerDate = data.data.data
                data.data.data.forEach((item, index) => {
                    if (item.id == 30) {
                        this.offerSrc = item.sj_link
                        // console.log(this.offerSrc );
                    }
                });
                // this.$nextTick(() => {
                //     this.mySwiper5Init()
                // })
            })
        },
        onSubmit() {
            var currentURL = window.location.href;
            var status = ''
            // 检查 URL 中是否包含某些关键词，来判断使用的搜索引擎
            if (currentURL.includes("google.com")) {
                status = "用户正在使用 Google 搜索引擎"
            } else if (currentURL.includes("bing.com")) {
                status = "用户正在使用 Bing 搜索引擎"
            } else if (currentURL.includes("yahoo.com")) {
                status = "用户正在使用 Yahoo 搜索引擎"
            } else if (currentURL.includes("baidu.com")) {
                status = "用户正在使用 百度 搜索引擎"
            } else {
                status = "无法确定用户使用的搜索引擎"
            }
            var patt = /^1[3-9][0-9]{9}$/

            if (this.formLabelAlign.name == "" || this.formLabelAlign.tel == "") {
                // console.log("姓名和手机号不能为空");
                this.$message.error('姓名和手机号不能为空');

            } else {
                // console.log("不是空");

                if (patt.test(this.formLabelAlign.tel)) {

                    // console.log("可以发请求");
                    // console.log(this.formInline);
                    axios.post("https://public.rossoarts.com/index/common/getform", {
                        "name": this.formLabelAlign.name,
                        "tel": this.formLabelAlign.tel,
                        "major": this.formLabelAlign.major,
                        "select_campus": this.formLabelAlign.region,
                        "intended_country": this.formLabelAlign.region2,
                        "url": window.location.href,
                        "status": status,
                    }).then((data) => {
                        console.log(data, "response");
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }).catch(function (error) {
                        console.log(error, "error");
                    });

                } else {
                    console.log("手机号格式不正确");
                    this.$message.error('手机号格式不正确');

                }

            }


        },
        getSelected() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstCase?limit=24&page=1").then((data) => {
                // console.log(data.data);
                // console.log(this.arrGroup(data.data, 4));
                this.selectedData = this.arrGroup(data.data, 4)
                this.$nextTick(() => {
                    this.mySwiper333Stick()
                })
            })
        },

        getTutors1() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstTeacher?category=1&limit=12").then((data) => {
                // console.log(data.data);
                this.tutors1Data = data.data
                // this.$nextTick(() => {
                //   this.mySwiper331Stick()
                // })
            })
        },
        getTutors2() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstTeacher?category=2&limit=12").then((data) => {
                // console.log(data.data);
                this.tutors1Data2 = data.data
                this.$nextTick(() => {
                    this.mySwiper331Stick()
                })
            })
        },
        getSchool() {
            axios.get("https://public.rossoarts.com/api/v1/getFirstOffer?limit=36&page=1").then((data) => {
                // console.log(this.arrGroup(data.data.data, 4));
                this.schoolData = this.arrGroup(data.data.data, 6)
                this.$nextTick(() => {
                    this.mySwiper2Stick()
                })
            })
        },
        oneEnv() {
            var envItems = document.getElementsByClassName("envItem")
            envItems[0].style.backgroundColor = "rgba(95, 157, 247, 1)"
            envItems[0].style.color = "#fff"
            envItems[0].style.fontWeight = "bold"
        },
        envChange(val) {
            var envItems = document.getElementsByClassName("envItem")
            this.mySwiper52tick()
            this.envImg1 = require(`../img/各校区门头图/${envItems[val].innerHTML}/官网/1.jpg`)
            this.envImg2 = require(`../img/各校区门头图/${envItems[val].innerHTML}/官网/2.jpg`)
            this.envImg3 = require(`../img/各校区门头图/${envItems[val].innerHTML}/官网/3.jpg`)
            this.envImg4 = require(`../img/各校区门头图/${envItems[val].innerHTML}/官网/4.jpg`)
            this.envImg5 = require(`../img/各校区门头图/${envItems[val].innerHTML}/官网/5.jpg`)


            for (var i = 0; i < envItems.length; i++) {
                envItems[i].style.backgroundColor = "#fff"
                envItems[i].style.color = "#000"
                envItems[i].style.fontWeight = "400"
            }
            envItems[val].style.backgroundColor = "rgba(95, 157, 247, 1)"
            envItems[val].style.color = "#fff"
            envItems[val].style.fontWeight = "bold"
            // console.log(val, this.envImg[val],s5Img1[0].style);
            // s5p1.innerHTML = this.envCont[val].name
            // s5p2.innerHTML = this.envCont[val].ename
            s5p3.innerHTML = this.envCont[val].address

            // this.envMainImg = this.envImg[val]

        },
        getArtsNew() {
            axios.get("https://public.rossoarts.com/api/v1/getArtsNews?category=0&limit=8&page=1").then((data) => {
                // console.log(data.data.data.data, "4.4");
                this.artsFirst = data.data.data.data[0]
                this.artsFirstId = data.data.data.data[0].id
                this.artsNewdata = data.data.data.data
                this.$nextTick(() => {
                    this.mySwiper7tick()
                    this.mySwiper5Init()
                })

                // var arr = data.data.data.data.splice(1, data.data.data.data.length);
                // console.log(data.data.data.data.splice(1, data.data.data.data.length));
                // const res = [];
                // for (let i = 0; i < arr.length - 1; i += 2) {
                //   let index = Math.floor(i / 2);
                //   const obj = {
                //     x: arr[i],
                //     y: arr[i + 1],
                //   };
                //   res[index] = obj;
                // }
                // console.log("res", res);

                // this.artsNewdata = res
            })
        },
        s8next() {
            this.s8I++;

            var s8rTop = document.getElementsByClassName("s8r_top")
            s8banner.style.transform = `translateX(${-6.8 * this.s8I}rem)`
            s8banner.style.transition = 'all .36s'
            if (this.s8I == 6) {
                this.s8I = 0;

                setTimeout(() => {
                    s8banner.style.transform = 'translate(0,0)';
                    s8banner.style.transition = 'none'
                }, 360);

            }



            // console.log("s8nex", this.s8I);
            collectionName.innerHTML = this.collectionName[this.s8I]
            artsName.innerHTML = this.artsName[this.s8I]
        },
        s8pre() {
            this.s8I--;
            if (this.s8I == -1) {
                this.s8I = 5;

                s8banner.style.transition = 'none'

                s8banner.style.transform = `translateX(${-6.8 * (this.s8I + 1)}rem)`
                setTimeout(() => {


                    s8banner.style.transform = 'translateX(' + (-this.s8I * 6.8) + 'rem)';
                    s8banner.style.transition = 'all .36s'

                }, 1);


            } else {
                s8banner.style.transition = 'all 0.36s'
                s8banner.style.transform = `translateX(${-6.8 * this.s8I}rem)`
            }


            collectionName.innerHTML = this.collectionName[this.s8I]
            artsName.innerHTML = this.artsName[this.s8I]
        },
        togCourses(val) {
            var s33_togbtn = document.getElementsByClassName("s33_togbtn")
            var s33_cont1 = document.getElementsByClassName("s33_cont1")
            for (var i = 0; i < s33_togbtn.length; i++) {
                s33_togbtn[i].style.color = "#000"
                s33_togbtn[i].style.backgroundColor = "#fff"
                s33_cont1[i].style.display = "none"
            }
            s33_togbtn[val].style.color = "#fff"
            s33_togbtn[val].style.backgroundColor = "#5F9DF7"
            s33_cont1[val].style.display = "block"

        },
        jopkefu() {
            window.open("https://www11c1.53kf.com/webCompany.php?kf_sign=DI0MjMTY4MkzODE1NTc0NTYwMDMzMDAzNzIxNjgzNTM=&arg=10168353&style=1&guest_id=12950638033002&language=zh-cn&charset=gbk&referer=http%3A%2F%2Fpublic.rossoarts.com%2F&keyword=http%3A%2F%2Flocalhost%3A8081%2F&tpl=crystal_blue&uid=392070b4b477c77869f6a6a47a1dfee8&is_group=&timeStamp=1680242953439&ucust_id=", '_blank')
        },

        bannerJop1(val) {
            console.log(val);
            if (val == "https://tb.53kf.com/code/client/10168353/1") {
                window.open(val, '_blank')
            } else if (val == "http://music.rossoarts.com/") {
                window.open(val, '_blank')
            } else {
                this.$router.push(`${val}`)
            }
        },
        artNewJop(val) {
            console.log(val);
            this.$router.push(`/news/${val}.html`)
        },

        mySwiper2Stick() {
            var swiper = new Swiper(".mySwiper2", {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                observer: true,
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        },
        mySwiper331Stick() {
            var swiper = new Swiper(".mySwiper331", {
                pagination: {
                    clickable: true,
                    el: ".swiper-pagination",
                },
                loop: true,
                observer: true,

            });
        },
        mySwiper333Stick() {
            var swiper = new Swiper(".mySwiper333", {
                pagination: {
                    clickable: true,
                    el: ".swiper-pagination",
                },
                loop: true,
                observer: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        },
        mySwiper7tick() {
            var swiper = new Swiper(".mySwiper7", {
                loop: true,
                pagination: {
                    clickable: true,
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                observer: true,
            });
        },
        mySwiper52tick() {
            var swiper = new Swiper(".mySwiper52", {
                // spaceBetween: 30,
                // effect: "fade",
                // loop: true,
                observer: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        },
        mySwiper5Init() {
            var swiper = new Swiper(".mySwiper5", {
                // spaceBetween: 30,
                // effect: "fade",
                loop: true,
                // observer: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false,
                },
            });
        },
    },
    mounted() {

        this.mySwiper52tick()
        this.oneEnv()
        new Swiper('.mySwiper', {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 3,
            loop: true,
            loopFillGroupWithBlank: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

        });

        var swiper3 = new Swiper(".mySwiper3", {
            effect: "cards",
            grabCursor: true,
        });

        var swiper = new Swiper(".mySwiper6", {
            pagination: {
                clickable: true,
                el: ".swiper-pagination",
            },
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });



        var swiper = new Swiper(".mySwiper4", {
            pagination: {
                clickable: true,
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        var swiper = new Swiper(".mySwiper8", {
            loop: true,
            pagination: {
                clickable: true,
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        // var swiper = new Swiper(".mySwiper5", {
        //   loop: true,
        //   autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false,
        //   },
        //   pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true,
        //   },
        //   navigation: {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev",
        //   },

        // });



    }
}
</script>

